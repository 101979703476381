<ul class="nav first-level" role="menubar">
  <!--
  "Virtual" menu items that are always hidden.
  Used for size measurement.
  -->
  @for (item of menuItems; track item) {
    <li class="virtual" role="none">
      <a class="nav-item" role="none">
        <ng-container [ngTemplateOutlet]="item.content"></ng-container>
      </a>
      <ng-container [ngTemplateOutlet]="item.secondary"></ng-container>
    </li>
  }

  <!-- Mobile menu -->
  @if (navbar.isBurgerMenuBpMatched$ | async) {
    <li
      class="menudrop dropdown"
      role="none"
      vudDropdown
      #dropdown="vudDropdown"
      [class.open]="dropdown.isOpen$ | async"
    >
      <a
        class="dropdown-toggle"
        id="vud-navbar-mobile-menu"
        href="#"
        role="menuitem"
        aria-haspopup="true"
        draggable="false"
        vudDropdownToggle
        (click)="dropdown.toggle(); $event.preventDefault()"
      >
        <span i18n>Menu</span>
        <i class="icon-align-justify"></i>
      </a>
      <ul class="dropdown-menu is-mobile" aria-labelledby="vud-navbar-mobile-menu" vudDropdownMenu>
        <!-- Brand information that's visible only inside burger menu -->
        @if (navbar.brandOptions!.length > 0) {
          <li class="dropdown navbar-brand" role="none" [class.is-open]="brandOptionsOpen">
            <a
              class="dropdown-toggle dropped-apps-toggle"
              role="button"
              [attr.aria-expanded]="brandOptionsOpen"
              (click)="toggleBrandOptions()"
            >
              {{ activeBrandOption?.label }}
              <span class="caret"></span>
            </a>
          </li>
          @if (brandOptionsOpen) {
            @for (brandOption of navbar.brandOptions; track brandOption) {
              @if (!brandOption.active) {
                <li role="none" [class.active]="brandOption.active">
                  <a
                    role="menuitem"
                    [attr.aria-label]="brandOption.label"
                    [attr.href]="brandOption.href"
                    [class.active]="brandOption.active"
                    (click)="brandOption.click.emit($event)"
                  >
                    <ng-template [ngTemplateOutlet]="brandOption.content"></ng-template>
                  </a>
                </li>
              }
            }
          }
        }
        <!-- All menu options -->
        @if (!brandOptionsOpen) {
          @for (item of menuItems; track item; let i = $index) {
            <li
              role="none"
              [class.active]="item.isActive$ | async"
              [class.is-open]="item.isActive$ | async"
              [class.second-level-children]="true"
            >
              <a
                class="nav-item"
                [attr.aria-current]="item.isActive$ | async"
                [attr.href]="item.href"
                [class.has-children]="true"
                (click)="item.click.emit($event)"
              >
                <ng-container [ngTemplateOutlet]="item.content"></ng-container>
                @if (true) {
                  <span class="caret"></span>
                }
              </a>
              <ng-container [ngTemplateOutlet]="item.secondary"></ng-container>
            </li>
          }
        }
      </ul>
    </li>
  }

  <!-- Desktop menu -->
  @if ((navbar.isBurgerMenuBpMatched$ | async) === false) {
    @for (item of menuItems; track item; let i = $index) {
      @if (!hiddenMenuItems[i]) {
        <li role="none" [class.active]="item.isActive$ | async">
          <a
            class="nav-item"
            role="menuitem"
            [attr.aria-current]="item.isActive$ | async"
            [attr.href]="item.href"
            (click)="item.click.emit($event)"
          >
            <ng-container [ngTemplateOutlet]="item.content"></ng-container>
          </a>
          <ng-container [ngTemplateOutlet]="item.secondary"></ng-container>
        </li>
      }
    }
    <!-- "More" button for opening the hidden menu -->
    @if (anyHiddenMenuItem) {
      <li class="menudrop dropdown" role="none" [class.open]="moreMenuOpen">
        <!--
        note: `[attr.role]="'menuitem'"` is intentionally used to have a higher specificity,
        otherwise `cdkMenuTriggerFor` takes over and sets the role to `button`, yikes!
        -->
        <a
          class="dropdown-toggle"
          id="vud-navbar-more-menu"
          href="#"
          draggable="false"
          #moreMenuTrigger="cdkMenuTriggerFor"
          [attr.aria-haspopup]="anyHiddenMenuItem"
          [attr.role]="'menuitem'"
          [cdkMenuPosition]="moreMenuPosition"
          [cdkMenuTriggerFor]="moreMenu"
          (cdkMenuClosed)="moreMenuOpen = false"
          (cdkMenuOpened)="moreMenuOpen = true"
          (click)="$event.preventDefault()"
        >
          <span class="hidden" i18n>Menu</span>
          <i class="icon-align-justify"></i>
        </a>
        <ng-template #moreMenu>
          <ul class="vud-more-menu dropdown-menu" aria-labelledby="vud-navbar-more-menu" cdkMenu>
            <!-- Hidden menu options that didn't fit in the main menu bar -->
            @for (item of menuItems; track item; let i = $index) {
              @if (hiddenMenuItems[i]) {
                <li role="none" [class.active]="item.isActive$ | async">
                  <a
                    class="nav-item"
                    cdkMenuItem
                    [attr.aria-current]="item.isActive$ | async"
                    [attr.href]="item.href"
                    (click)="item.click.emit($event)"
                  >
                    <ng-container [ngTemplateOutlet]="item.content"></ng-container>
                  </a>
                  <ng-container [ngTemplateOutlet]="item.secondary"></ng-container>
                </li>
              }
            }
          </ul>
        </ng-template>
      </li>
    }
  }
</ul>
