@if ((showBackButton$ | async) === false && (stepperProperties$ | async) === null) {
  <di-left-menu-btn></di-left-menu-btn>
}
@if (showBackButton$ | async) {
  <li class="cursor-pointer">
    <a class="pl-12 d-flex align-items-center" routerLink="/">
      <span class="icon-md menu-icon move-left-icon"></span>
      <span class="pl-24" data-testId="back-to-employees-list" i18n="@@menu-stepper.back-to-employees-list"
        >Back to employees</span
      >
    </a>
  </li>
}
<div class="stepper-li">
  <div
    class="stepper stepper-vertical stepper-vertical-sm transition no-ellipsis"
    data-testid="stepper"
    role="navigation"
    aria-label="Stepper"
  >
    @if (stepperProperties$) {
      <ul>
        @for (step of stepperProperties$ | async; track step) {
          <li
            class="nav-step pl-12"
            data-testid="navigation-step"
            [ngClass]="{ active: step.active, passed: step.passed }"
          >
            <span class="text-left">{{ step.title }}</span>
          </li>
        }
      </ul>
    }
  </div>
</div>
