@for (toast of currentToasts; track toast; let i = $index) {
  <div>
    <di-toast
      [autoDismiss]="toast.autoDismiss"
      [id]="toast.id"
      [message]="toast.message"
      [type]="toast.type"
      (disposeEvent)="dispose(i)"
    ></di-toast>
  </div>
}
