@if (hasCheckbox) {
  <vud-checkbox
    data-testid="checkbox"
    [checked]="isCheckboxChecked"
    [hidden]="hasWarning"
    [id]="checkboxId"
    (change)="toggleCheckbox()"
  ></vud-checkbox>
}
@if (hasWarning || isSuccessful || hasError) {
  <span
    class="vismaicon vismaicon-sm vismaicon-filled"
    data-testid="visma-status-icon"
    role="status"
    [ngClass]="{
      'vismaicon-warning': hasWarning,
      'vismaicon-success': isSuccessful,
      'vismaicon-error': hasError,
    }"
  >
  </span>
}
@if (isInProgress) {
  <span
    class="spinner spinner-primary-grey spinner-xs"
    data-testId="row-spinner"
    role="progressbar"
    aria-label="Row spinner"
  >
  </span>
}
