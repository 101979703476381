<div
  class="alert alert-dismissible"
  [ngClass]="{ 'alert-warning': isAllDataShowing, 'alert-danger': isAllDataShowing && isErrorAlert }"
>
  @if (!shownOnlyGeneralMessage) {
    <div class="d-flex justify-content-between align-items-center">
      @if (isAllDataShowing) {
        <div class="d-flex">
          @if (employeesCount > 1) {
            <span i18n="@@grid-status-row.employees-with-warnings">
              You have <span class="font-weight-bold">{{ employeesCount }} employees</span> that need your attention!
            </span>
          }
          @if (employeesCount === 1) {
            <span i18n="@@grid-status-row.employee-with-warning">
              You have <span class="font-weight-bold">{{ employeesCount }} employee</span> that needs your attention!
            </span>
          }
          <button
            class="btn btn-link py-0"
            type="button"
            (click)="onShowDataClick()"
            i18n="@@grid-status-row.view-employees"
          >
            View employees
          </button>
        </div>
      }
      @if (!isAllDataShowing) {
        <div class="d-flex">
          @if (!isErrorAlert) {
            <span i18n="@@grid-status-row.employees-warnings-shown">You are shown employees with warnings.</span>
          }
          @if (isErrorAlert) {
            <span i18n="@@grid-status-row.employees-errors-shown">You are shown employees with errors.</span>
          }
          <button class="btn btn-link py-0" type="button" (click)="onShowDataClick()" i18n="@@grid-status-row.show-all">
            Show all
          </button>
        </div>
      }
    </div>
  }
  <div>
    <ng-content></ng-content>
  </div>
</div>
