import { WsPing } from '@bo-schema-ws/client-payloads/ws-ping';

// message schema for sending to clients
export interface WsClientMessageDto {
  // generated when the message is created
  messageId: string;
  createdTimeIso: string;

  payload: WsClientPayloadSchema;
}

export type WsClientPayloadSchema =
  // infrastructure
  { type: WsClientPayloadEnum.Ping; value: WsPing };

export enum WsClientPayloadEnum {
  // Must be sent every 2 minutes to make the browser keep the connection open
  // (and AWS which would close it after 10 minutes).
  // Triggers a `Pong` in reply, and a `ConnectionExpires` when relevant.
  Ping = 'ping',
}
