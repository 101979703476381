<div class="panel p-relative">
  <div class="cursor-pointer mh-160" [ngClass]="{ disabled: isCardDisabled }" (click)="clickCard.emit()">
    <div class="panel-body w-100 d-flex align-items-center justify-content-between p-relative">
      <div class="mr-12">
        <div class="d-flex align-items-center mb-12">
          <span class="icon-40 d-print-block mr-12 bg-size-cover" role="img" [ngClass]="iconClass"></span>
          <h3 class="m-0"><ng-content select="di-grid-card-title"></ng-content></h3>
        </div>
        <div class="text-truncate-2">
          <ng-content select="di-grid-card-desc"></ng-content>
        </div>
      </div>
      @if (!isExternalLink && !isCardDisabled) {
        <span class="grid-next-icon icon-16" role="button"></span>
      }
      @if (isExternalLink && !isCardDisabled) {
        <span class="vismaicon vismaicon-export vismaicon-sm p-absolute top-12 right-12" role="button"></span>
      }
    </div>
  </div>
  @if (isCardDisabled) {
    <span
      class="vismaicon vismaicon-filled vismaicon-sm vismaicon-info p-absolute top-12 right-12"
      data-testid="info-icon"
      [vudTooltip]="functionDisabledTooltip"
    ></span>
  }
</div>
<ng-template #functionDisabledTooltip>
  <span i18n="@@grid-card.card-disabled-tooltip"
    >Sorry, this function is temporarily disabled. Please try again later.</span
  >
</ng-template>
