<div class="modal-header">
  <h4 class="modal-title" i18n="@@tags-error-modal.title">Something went wrong</h4>
</div>
<div class="modal-body">
  <span i18n="@@tags-error-modal.body">
    We couldn't load tags at this moment. Please try again or contact Support if the problem persists.
  </span>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary"
    type="button"
    (click)="closeModal(true)"
    i18n="@@get-employees-error-modal.btn-try-again"
  >
    Try again
  </button>
  <button class="btn" type="button" routerLink="/" (click)="closeModal()" i18n="@@tags-error-modal.back-to-employees">
    Back to employees
  </button>
</div>
