import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { EventTypes } from './toaster.enum';
import { ToasterEvent } from './toaster.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  toastEvents$: Observable<ToasterEvent>;
  private _toastEvents = new Subject<ToasterEvent>();

  constructor() {
    this.toastEvents$ = this._toastEvents.asObservable();
  }

  showToast(message: string, type: EventTypes, id?: string, autoDismiss: number | false = 10000) {
    this._toastEvents.next({
      message,
      type,
      id,
      autoDismiss,
    });
  }

  hideToast(type: EventTypes, id: string) {
    this._toastEvents.next({
      type,
      id,
    });
  }
}
