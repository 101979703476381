import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { timer } from 'rxjs';

import { EventTypes } from '../toaster.enum';

@Component({
  selector: 'di-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ToastComponent implements OnInit {
  @Output() disposeEvent = new EventEmitter();
  @ViewChild('toastElement', { static: true }) toastEl: ElementRef;
  EventTypes = EventTypes;

  @Input() type: EventTypes;
  @Input() message: string;
  @Input() id: string;
  @Input() autoDismiss: number | false;

  ngOnInit() {
    this.show();
  }

  show() {
    if (!this.autoDismiss) {
      return;
    }
    timer(this.autoDismiss)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.hide();
      });
  }

  hide() {
    this.disposeEvent.emit();
  }
}
