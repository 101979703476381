export const environment = {
  type: 'internal',
  production: false,
  enableBrowserLogs: true,
  enableRUM: false,
  dummyAuth: false,
  dataImportApiBaseUrl: 'https://api.di.hrm.int.test.visma.net',
  wsBaseUrl: 'wss://ws.di.hrm.int.test.visma.net',
  ldClientSideID: '608646cc9151340be39d6364',
  signinUrl: '',
  loginRedirectUrl: '',
  wootricAccountToken: null,
  vismaHomeUrl: 'https://home.stag.visma.com/',
  vismaEmpmanUrl: 'https://web.employeecore1.hrm.stag.visma.net/uiempmanapp/',
  vismaAboutUrl: 'https://wagerun.cj.hrm.int.test.visma.net/',
  vismaCalendarUrl: 'https://web.calendar.hrm.int.test.visma.net/',
  empManModuleId: 2257,
  payrollUiUrl: 'https://payrollui.no.int.test.visma.net/',
  payrollPortal:
    'https://web.calendar.hrm.stag.visma.net/absence/_Svelte/dist/scripts/es/ClientPortalComponentProvider.js',
  walkmeSrc:
    'https://eu-cdn.walkme.com/users/156f0c8fa19a4b7cbd7437d888ce43cc/test/walkme_private_156f0c8fa19a4b7cbd7437d888ce43cc_https.js',
  walkmeIntegrity: 'sha256-xA7YXeCjh992RrqQgJYVJkaC1WKs0iPQ4lE/tNN65b4=',
  wagerunUrl: 'https://wagerun.cj.hrm.stag.visma.net',
};
