import {
  ContractSalaryTypeEnum,
  EmployeeStatusEnum,
  EmploymentFormEnum,
  OperationStatusEnum,
  OperationTypeEnum,
  PaymentTypeEnum,
  PositionEndReasonEnum,
  SalaryTypeEnum,
  SetOfAccountEnum,
  TaskStatusEnum,
  TypeOfPositionEnum,
} from '@data-import/data-access/bulk-operations-api';

export class EnumLocalizationHelper {
  static getOperationStatus(status: OperationStatusEnum): string {
    switch (status) {
      case OperationStatusEnum.Successful:
        return $localize`:@@enum-import-status.successful:Successful`;
      case OperationStatusEnum.Incomplete:
        return $localize`:@@enum-import-status.incomplete:Incomplete`;
      case OperationStatusEnum.InProgress:
        return $localize`:@@enum-import-status.in-progress:In Progress`;
      default:
        if (!status) return '';
        console.warn(`There is no such operation status ${status}`);
        return status;
    }
  }

  static getTaskStatus(status: TaskStatusEnum): string {
    switch (status) {
      case TaskStatusEnum.Successful:
        return $localize`:@@enum-import-status.successful:Successful`;
      case TaskStatusEnum.Created:
        return $localize`:@@enum-import-status.created:Created`;
      case TaskStatusEnum.Failed:
        return $localize`:@@enum-import-status.failed:Failed`;
      case TaskStatusEnum.Waiting:
        return $localize`:@@enum-import-status.waiting:Waiting`;
      case TaskStatusEnum.PollingTimeout:
        return $localize`:@@enum-import-status.polling-timeout:Polling timeout`;
      case TaskStatusEnum.Polling:
        return $localize`:@@enum-import-status.polling:Polling`;
      default:
        if (!status) return '';
        console.warn(`There is no such task status ${status}`);
        return status;
    }
  }

  static getSalaryType(value: SalaryTypeEnum): string {
    switch (value) {
      case SalaryTypeEnum.Period:
        return $localize`:@@enum-localization-helper.salary-type-fixed:Fixed salary`;
      case SalaryTypeEnum.Hourly:
        return $localize`:@@enum-localization-helper.salary-type-variable:Variable salary`;
      case SalaryTypeEnum.Unsupported:
        return $localize`:@@enum-localization-helper.salary-type-unsupported:Unsupported`;
      default:
        if (!value) return '';
        console.warn(`There is no such salary type ${value}`);
        return value;
    }
  }

  static getEmployeeStatus(value: EmployeeStatusEnum): string {
    switch (value) {
      case EmployeeStatusEnum.Active:
        return $localize`:@@enum-localization-helper.employee-status-active:Active`;
      case EmployeeStatusEnum.Inactive:
        return $localize`:@@enum-localization-helper.employee-status-inactive:Inactive`;
      case EmployeeStatusEnum.Future:
        return $localize`:@@enum-localization-helper.employee-status-future:Future`;
      case EmployeeStatusEnum.Draft:
        return $localize`:@@enum-localization-helper.employee-status-draft:Draft`;
      case EmployeeStatusEnum.Unknown:
        return $localize`:@@enum-localization-helper.employee-status-unknown:Unknown`;
      default:
        if (!value) return '';
        console.warn(`There is no such employee status ${value}`);
        return value;
    }
  }

  static getEmploymentForm(value: EmploymentFormEnum): string {
    switch (value) {
      case EmploymentFormEnum.Permanent:
        return $localize`:@@enum-localization-helper.employment-form-permanent:Permanent`;
      case EmploymentFormEnum.Temporary:
        return $localize`:@@enum-localization-helper.employment-form-temporary:Temporary`;
      default:
        if (!value) return '';
        console.warn(`There is no such employment form ${value}`);
        return value;
    }
  }

  static getOperationType(value: OperationTypeEnum): string {
    switch (value) {
      case OperationTypeEnum.AbsenceRegistrationImport:
        return $localize`:@@enum-localization-helper.operation-type-absences:Absences`;
      case OperationTypeEnum.VacationBalanceImport:
        return $localize`:@@enum-localization-helper.operation-type-vb:Vacation balances`;
      case OperationTypeEnum.SalaryUpdateV2:
        return $localize`:@@enum-localization-helper.operation-type-salaries:Salary update`;
      case OperationTypeEnum.EndFixedTransactions:
        return $localize`:@@enum-localization-helper.operation-type-fixed-transactions:End fixed transactions`;
      case OperationTypeEnum.ChangeTimeAgreements:
        return $localize`:@@enum-localization-helper.operation-type-time-agreements:Time agreements`;
      case OperationTypeEnum.PositionTagsUpdate:
        return $localize`:@@enum-localization-helper.operation-type-position-tags-update:Position tags update`;
      case OperationTypeEnum.EndPositions:
        return $localize`:@@enum-localization-helper.operation-type-position-end-positions:End positions`;
      case OperationTypeEnum.CostUnitValueUpsert:
        return $localize`:@@enum-localization-helper.operation-type-cost-unit-edit:Cost unit edit`;
      case OperationTypeEnum.AssignUser:
        return $localize`:@@enum-localization-helper.operation-type-create-user:Create users`;
      case OperationTypeEnum.ChangeManager:
        return $localize`:@@enum-localization-helper.operation-type-change-managers:Change managers`;
      case OperationTypeEnum.EditFixedTransactions:
        return $localize`:@@enum-localization-helper.operation-type-edit-transactions:Edit fixed transactions`;
      default:
        if (!value) return '';
        console.warn(`There is no such operation type ${value}`);
        return value;
    }
  }

  static getTypeOfPosition(value: TypeOfPositionEnum): string {
    switch (value) {
      case TypeOfPositionEnum.Ordinary:
        return $localize`:@@enum-localization-helper.type-of-position-ordinary:Ordinary contract`;
      case TypeOfPositionEnum.Pension:
        return $localize`:@@enum-localization-helper.type-of-position-pension:Pension and other remunerations without employment`;
      case TypeOfPositionEnum.Freelancer:
        return $localize`:@@enum-localization-helper.type-of-position-freelancer:Freelancer, Contractors, Only receiving honorarium`;
      case TypeOfPositionEnum.Maritime:
        return $localize`:@@enum-localization-helper.type-of-position-maritime:Maritime employment`;
      default:
        if (!value) return '';
        console.warn(`There is no such type of position ${value}`);
        return value;
    }
  }

  static getContractSalaryType(value: ContractSalaryTypeEnum): string {
    switch (value) {
      case ContractSalaryTypeEnum.Hourly:
        return $localize`:@@enum-localization-helper.contract-salary-hourly:Hourly`;
      case ContractSalaryTypeEnum.Yearly:
        return $localize`:@@enum-localization-helper.contract-salary-yearly:Yearly`;
      case ContractSalaryTypeEnum.Monthly:
        return $localize`:@@enum-localization-helper.contract-salary-monthly:Monthly`;
      default:
        if (!value) return '';
        console.warn(`There is no such contract salary type ${value}`);
        return value;
    }
  }

  static getPositionEndReason(value: PositionEndReasonEnum): string {
    switch (value) {
      case PositionEndReasonEnum.InvoluntaryTermination:
        return $localize`:@@enum-localization-helper.position-end-reason-involuntary:Involuntary termination`;
      case PositionEndReasonEnum.Resignation:
        return $localize`:@@enum-localization-helper.position-end-reason-resignation:Resignation`;
      case PositionEndReasonEnum.HiringTermsExpiration:
        return $localize`:@@enum-localization-helper.position-end-reason-terms-exp:Hiring terms expiration`;
      case PositionEndReasonEnum.DataEntryMistake:
        return $localize`:@@enum-localization-helper.position-end-reason-data-mistake:Data entry mistake`;
      case PositionEndReasonEnum.Technical:
        return $localize`:@@enum-localization-helper.position-end-reason-technical:Technical`;
      case PositionEndReasonEnum.Organizational:
        return $localize`:@@enum-localization-helper.position-end-reason-organizational:Organizational`;
      default:
        if (!value) return '';
        console.warn(`There is no such position end reason ${value}`);
        return value;
    }
  }

  static getPaymentType(value: PaymentTypeEnum): string {
    switch (value) {
      case PaymentTypeEnum.Bank:
        return $localize`:@@enum-localization-helper.payment-type-bank:Bank`;
      case PaymentTypeEnum.Cash:
        return $localize`:@@enum-localization-helper.payment-type-cash:Cash`;
      default:
        if (!value) return '';
        console.warn(`There is no such payment type: ${value}`);
        return value;
    }
  }

  static getSetOfAccount(value: SetOfAccountEnum): string {
    switch (value) {
      case SetOfAccountEnum.BlueCollar:
        return $localize`:@@enum-localization-helper.set-of-account-blue-collar:Blue collar`;
      case SetOfAccountEnum.WhiteCollar:
        return $localize`:@@enum-localization-helper.set-of-account-white-collar:White collar`;
      case SetOfAccountEnum.Other:
        return $localize`:@@enum-localization-helper.set-of-account-other:Other`;
      default:
        if (!value) return '';
        console.warn(`There is no such set of account: ${value}`);
        return value;
    }
  }
}
