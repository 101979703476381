<div
  class="toast bottom-0 left-12 top-auto tw-w-40"
  role="alert"
  #toastElement
  [ngClass]="{
    'toast-info': type === EventTypes.Info,
    'toast-success': type === EventTypes.Success,
    'toast-danger': type === EventTypes.Error,
  }"
>
  <button class="close" type="button" (click)="hide()"></button>
  {{ message }}
</div>
