import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VismaUnifiedDesignModule } from '@vismaux/ngx-vud';

import { FeatureFlagModule } from '@data-import/feature-flag';

import { BackToEditStepBtnComponent } from './components/back-to-edit-step-btn/back-to-edit-step-btn.component';
import { CollapsibleAreaComponent } from './components/collapsible-area/collapsible-area.component';
import { EmployeesListBtnComponent } from './components/employees-list-btn/employees-list-btn.component';
import { FileSelectComponent } from './components/file-select/file-select.component';
import { FilterCheckboxComponent } from './components/filter-checkbox/filter-checkbox.component';
import { FilterTotalsComponent } from './components/filter-totals/filter-totals.component';
import { GridBackToEmployeesBtnComponent } from './components/grid-back-to-employees-btn/grid-back-to-employees-btn.component';
import { GridCardDescComponent } from './components/grid-card/components/grid-card-desc.component';
import { GridCardTitleComponent } from './components/grid-card/components/grid-card-title.component';
import { GridCardComponent } from './components/grid-card/grid-card.component';
import { GridErrorRowRendererComponent } from './components/grid-error-row-renderer/grid-error-row-renderer.component';
import { GridHeaderTopComponent } from './components/grid-header-top/grid-header-top.component';
import { GridResultsStatusPanelComponent } from './components/grid-results-status-panel/grid-results-status-panel.component';
import { GridStatusColCellRendererComponent } from './components/grid-status-col-cell-renderer/grid-status-col-cell-renderer.component';
import { GridStatusRowComponent } from './components/grid-status-row/grid-status-row.component';
import { LeftMenuBtnComponent } from './components/left-menu/left-menu-btn/left-menu-btn.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MenuStepperComponent } from './components/left-menu/menu-stepper/menu-stepper.component';
import { NavbarModule } from './components/navbar';
import { PollingTimeoutTableErrorComponent } from './components/polling-timeout-table-error/polling-timeout-table-error.component';
import { SortItemsComponent } from './components/sort-items/sort-items.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TableStatusColComponent } from './components/table-status-col/table-status-col.component';
import { TagsErrorModalComponent } from './components/tags-error-modal/tags-error-modal.component';
import { TagLabelsMoreComponent } from './components/tags/tag-label-more/tag-labels-more.component';
import { TagLabelsComponent } from './components/tags/tag-labels/tag-labels.component';
import { TagOptionComponent } from './components/tags/tag-option/tag-option.component';
import { ToastComponent } from './components/toaster/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { UploadErrorsComponent } from './components/upload-errors/upload-errors.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DragAndDropFileSelectorDirective } from './directives/drag-and-drop-file-selector.directive';
import { RequiredInputDirective } from './directives/required-input.directive';
import { WootricDirective } from './directives/wootric.directive';
import { LocalizedEnumPipe } from './localizers/localized-enum.pipe';
import { LocalizedInternationalIdPipe } from './localizers/localized-international-id.pipe';
import { LocalizedStringPipe } from './localizers/localized-string.pipe';
import { LocalizedTypeOfIdPipe } from './localizers/localized-type-of-id.pipe';
import { ActiveTimelineItemPipe } from './pipes/active-timeline-item.pipe';
import { ArrayIncludesPipe } from './pipes/array-includes.pipe';
import { TagsByIdPipe } from './pipes/tags-by-id.pipe';
import { ValueByIdPipe } from './pipes/value-by-id.pipe';

@NgModule({
  declarations: [
    FileSelectComponent,
    DragAndDropFileSelectorDirective,
    StepperComponent,
    UploadErrorsComponent,
    WootricDirective,
    ClickOutsideDirective,
    SortItemsComponent,
    FilterCheckboxComponent,
    LocalizedStringPipe,
    FilterTotalsComponent,
    TableStatusColComponent,
    LocalizedEnumPipe,
    ValueByIdPipe,
    RequiredInputDirective,
    CollapsibleAreaComponent,
    MenuStepperComponent,
    LeftMenuBtnComponent,
    LeftMenuComponent,
    ActiveTimelineItemPipe,
    TagLabelsComponent,
    TagOptionComponent,
    TagsErrorModalComponent,
    ArrayIncludesPipe,
    TagsByIdPipe,
    EmployeesListBtnComponent,
    TagLabelsMoreComponent,
    GridCardComponent,
    GridCardTitleComponent,
    GridCardDescComponent,
    BackToEditStepBtnComponent,
    ToastComponent,
    ToasterComponent,
    PollingTimeoutTableErrorComponent,
    LocalizedTypeOfIdPipe,
    LocalizedInternationalIdPipe,
    GridStatusRowComponent,
    GridBackToEmployeesBtnComponent,
    GridResultsStatusPanelComponent,
    GridStatusColCellRendererComponent,
    GridErrorRowRendererComponent,
    GridHeaderTopComponent,
  ],
  imports: [
    CommonModule,
    VismaUnifiedDesignModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FeatureFlagModule,
  ],
  exports: [
    DragAndDropFileSelectorDirective,
    FileSelectComponent,
    StepperComponent,
    UploadErrorsComponent,
    WootricDirective,
    ClickOutsideDirective,
    SortItemsComponent,
    FilterCheckboxComponent,
    LocalizedStringPipe,
    FilterTotalsComponent,
    TableStatusColComponent,
    LocalizedEnumPipe,
    ValueByIdPipe,
    CollapsibleAreaComponent,
    MenuStepperComponent,
    LeftMenuBtnComponent,
    LeftMenuComponent,
    ActiveTimelineItemPipe,
    TagLabelsComponent,
    TagOptionComponent,
    TagsByIdPipe,
    EmployeesListBtnComponent,
    TagLabelsMoreComponent,
    GridCardComponent,
    GridCardTitleComponent,
    GridCardDescComponent,
    NavbarModule,
    BackToEditStepBtnComponent,
    ToastComponent,
    ToasterComponent,
    PollingTimeoutTableErrorComponent,
    LocalizedTypeOfIdPipe,
    LocalizedInternationalIdPipe,
    GridStatusRowComponent,
    GridHeaderTopComponent,
    RequiredInputDirective,
  ],
})
export class SharedModule {}
